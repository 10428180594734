import {
    EntityTypes,
    LookupDataEnums,
    ReportStatusTypesEnum,
    ReportUserTypesEnum,
} from '../../utilities/Constants';
import { IReportBaseModel, IReportUserModel } from '../../interfaces/Report/IReport';
import React, { useEffect, useState } from 'react';

import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { CollabActHistory } from './CollabActHistory';
import { IEntityLease } from '../../interfaces/IEntityLease';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IValidation } from '../../interfaces/IError';
import { IconButton } from '../../shared/buttons/IconButton';
import { Modal } from 'react-bootstrap';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGetLookupData } from '../../shared/react-query-hooks/useGetLookupData';
import { useObtainEntityLeaseMutation } from '../../shared/react-query-hooks/mutations/useObtainEntityLeaseMutation';

enum FormFields {
    ReportName = 'REPORT_NAME',
    ReportStatus = 'REPORT_STATUS',
}

interface ISaveReportModalProps {
    alertGuid?: string;
    availableUsers: IMultiSelectOptions[];
    closeButtonText: string;
    disableReaders?: boolean;
    executeSaveReport: (
        report: IReportBaseModel,
        reportUsers: IReportUserModel[],
        readers: IReportUserModel[],
        redirectOnSave?: boolean
    ) => void;
    leaseDuration: number;
    onClose: () => void;
    onLeaseObtained: (lease: IEntityLease) => void;
    onFailedToObtainLease: (currentLease: IEntityLease) => void;
    open: boolean;
    readonly: boolean;
    report: IReportBaseModel;
    reportCollaborators: IReportUserModel[];
    reportReaders: IReportUserModel[];
    showEditButton?: boolean;
    title?: string;
}

export function SaveReportModal(props: ISaveReportModalProps) {
    const auth = useAuthContext();
    const obtainEntityLeaseMutation = useObtainEntityLeaseMutation();

    const [reportStatusOptions, setReportStatusOptions] = useState<IMultiSelectOptions[]>([]);
    const [localReadonly, setLocalReadonly] = useState<boolean>(props.readonly);
    const [collaborators, setCollaborators] = useState<IMultiSelectOptions[]>(
        props.availableUsers
            .filter((user) =>
                props.reportCollaborators.map((user) => user.userGuid).includes(user.guid)
            )
            .map((user) => ({
                ...user,
                isFixed: !props.alertGuid ? user.guid === auth.user.guid : false,
            }))
    );
    const [readers, setReaders] = useState<IMultiSelectOptions[]>(
        props.availableUsers.filter((availUser) =>
            props.reportReaders.map((user) => user.userGuid).includes(availUser.guid)
        )
    );
    const [originalReaders] = useState<IMultiSelectOptions[]>(
        props.availableUsers.filter((user) =>
            props.reportReaders.map((user) => user.userGuid).includes(user.guid)
        )
    );
    const [validation, setValidation] = useState<IValidation>({});
    const [reportName, setReportName] = useState<string>(props?.report?.name ?? '');
    const [reportStatusId, setReportStatusId] = useState<number | null>(
        props?.report?.status?.id ?? null
    );

    const { data: reportStatusTypes, isSuccess: reportStatusLookupSuccess } = useGetLookupData(
        LookupDataEnums.ReportStatusTypes
    );

    const validate = (): IValidation => {
        const errors: { [key: string]: string[] } = {};

        if (!reportName) {
            errors[FormFields.ReportName] = Array.isArray(errors[FormFields.ReportName])
                ? [...errors[FormFields.ReportName], 'Required']
                : ['Required'];
        }

        if (!reportStatusId) {
            errors[FormFields.ReportStatus] = Array.isArray(errors[FormFields.ReportStatus])
                ? [...errors[FormFields.ReportStatus], 'Required']
                : ['Required'];
        }

        return errors;
    };

    const handleSaveReport = async () => {
        const errors = validate();

        if (Object.keys(errors)?.length > 0) {
            setValidation(errors);
            return;
        }

        if (!reportStatusId || !reportName) return;

        const collabUsers = collaborators?.map((item: IMultiSelectOptions) => ({
            userGuid: item.guid,
            reportUserTypeId: ReportUserTypesEnum.Collaborator,
        }));

        const reportReaders = readers?.map((item: IMultiSelectOptions) => ({
            userGuid: item.guid,
            reportUserTypeId: ReportUserTypesEnum.Reader,
        }));

        if (props?.executeSaveReport)
            props.executeSaveReport(
                { ...props.report, name: reportName, status: { id: reportStatusId } },
                collabUsers,
                reportReaders ?? [],
                true
            );

        props.onClose();
    };

    const handleReportNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value?.length > 0) {
            setValidation((prev) => {
                let newValidation = {};
                if (FormFields.ReportName in prev) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [FormFields.ReportName]: _, ...rest } = prev;
                    newValidation = rest;
                }
                return newValidation;
            });
        }

        setReportName(value);
    };

    const handleReportStatusChange = (option: any) => {
        if (option.id) {
            setValidation((prev) => {
                let newValidation = {};
                if (FormFields.ReportStatus in prev) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [FormFields.ReportStatus]: _, ...rest } = prev;
                    newValidation = rest;
                }
                return newValidation;
            });
        }

        setReportStatusId(option.id);
    };

    const handleEditClick = () => {
        // obtain lease and then make form editable

        if (!props.report?.guid) return;

        const durationInMs = props.leaseDuration;

        obtainEntityLeaseMutation
            .mutateAsync({
                entityTypeId: EntityTypes.Report,
                entityGuid: props.report?.guid,
                durationInMs,
            })
            .then((result) => {
                if (result?.success) {
                    setLocalReadonly(false);
                    props.onLeaseObtained(result.currentLease);
                } else if (result) {
                    props.onFailedToObtainLease(result.currentLease);
                }
            })
            .catch(() => {
                // todo: show error message
            });
    };

    useEffect(() => {
        if (reportStatusLookupSuccess) {
            const reportStatusOptions = CheckmateSelectHelper.getLookupOptions(
                reportStatusTypes ?? [],
                false
            );
            setReportStatusOptions(reportStatusOptions);
        }
    }, [reportStatusLookupSuccess]);

    useEffect(() => {
        setValidation({});
    }, [props.open]);

    useEffect(() => {
        if (props.readonly) {
            setCollaborators((prev) => {
                return prev.map((user) => ({ ...user, isFixed: true }));
            });
        }
    }, [props.readonly]);

    const availableReaders = props.availableUsers
        .filter((availUser) => availUser.guid !== auth.user.guid)
        .filter(
            (availUser) =>
                !collaborators.map((collabUser) => collabUser.guid).includes(availUser.guid)
        );

    return (
        <>
            <Modal centered show={props.open} onHide={props.onClose} backdrop={false}>
                <Modal.Header>
                    <Modal.Title>{props.title ?? 'Save Report'}</Modal.Title>
                    {props.showEditButton && (
                        <IconButton
                            buttonCssClass="mr-0 btn-no-bg"
                            icon="fal fa-edit"
                            onClick={handleEditClick}
                            text="Edit"
                        />
                    )}
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="control-label">Report Name*</label>
                        </div>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                disabled={localReadonly}
                                value={reportName}
                                onChange={handleReportNameChange}
                            />
                            <div className="text-danger mb-1">
                                {validation[FormFields.ReportName]}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="control-label">Report Status*</label>
                        </div>
                        <div className="col-sm-9">
                            <CheckmateSelect
                                isDisabled={
                                    localReadonly ||
                                    props.report.status?.id === ReportStatusTypesEnum.Published
                                }
                                options={reportStatusOptions}
                                value={reportStatusOptions.find((x) => x.id === reportStatusId)}
                                onChange={handleReportStatusChange}
                            />
                            <div className="text-danger mb-1">
                                {validation[FormFields.ReportStatus]}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="control-label">Collaborator(s)</label>
                        </div>
                        <div className="col-sm-9">
                            <CheckmateSelect
                                isDisabled={localReadonly}
                                options={props.availableUsers}
                                isMulti
                                value={collaborators}
                                onChange={(options: any) => {
                                    setCollaborators(options);
                                }}
                            />
                        </div>
                    </div>
                    {reportStatusId === ReportStatusTypesEnum.Published && (
                        <div className="row mb-2">
                            <div className="col-sm-3">
                                <label className="control-label">Reader(s)</label>
                            </div>
                            <div className="col-sm-9">
                                <CheckmateSelect
                                    isDisabled={props.disableReaders}
                                    isMulti
                                    options={availableReaders}
                                    value={readers}
                                    onChange={setReaders}
                                />
                            </div>
                        </div>
                    )}
                    {props.report.guid &&
                        props.alertGuid &&
                        props.reportCollaborators.length > 0 && (
                            <div className="mb-2">
                                <hr />
                                <CollabActHistory
                                    alertGuid={props.alertGuid}
                                    reportGuid={props.report.guid}
                                    reportStatus={props.report.status?.id}
                                />
                            </div>
                        )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default" onClick={props.onClose}>
                        {props.closeButtonText}
                    </button>
                    {(!localReadonly ||
                        JSON.stringify(readers) !== JSON.stringify(originalReaders)) && (
                        <button className="btn btn-orange" onClick={handleSaveReport}>
                            Save
                        </button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
