import * as React from 'react';

import { IKpiModel, IKpiSummaryCrosstabModel } from '../../interfaces/IKpiView';
import { MyDocumentsTypesEnum, UserRightsEnum } from '../../utilities/Constants';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import Html5ReportViewer from '../../components/report/Html5ReportViewer';
import { IReportParametersModel } from '../../interfaces/Report/IReport';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import moment from 'moment';

const _apiClient = new ApiClient();

interface ITrendsProps {
    key: string;
    user: IUserModel;
    refreshIdentifier?: string;
}

interface ITrendsState {
    pendingResponse: boolean;
    reportViewerHeight?: number;
    validation: IValidation;
    reportData?: IKpiModel;
    summaryData: IKpiSummaryCrosstabModel[];
    summaryYears: string[];
    reportParameters: IReportParametersModel;
    refreshReport: boolean;
    showGeneratedLiveButton: boolean;
    showSettingsModal: boolean;
    renderComplete?: boolean;
}

export class Trends extends React.Component<ITrendsProps, ITrendsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            validation: {},
            summaryData: [],
            summaryYears: [],
            reportParameters: {
                reportType: { id: 23 },
                getRealTimeData: false,
                date: {
                    type: 1,
                    startDate: '',
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                },
            },
            refreshReport: false,
            showGeneratedLiveButton: Authorization.userHasRight(
                UserRightsEnum.KPIViewGenerateLive,
                this.props.user
            ),
            showSettingsModal: false,
            reportData: undefined,
        };
    }

    componentDidMount() {
        this.getReportData();
    }
    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };
    componentDidUpdate(prevProps: ITrendsProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier !== this.props.refreshIdentifier
        ) {
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    summaryData: [],
                    summaryYears: [],
                    reportParameters: {
                        reportType: { id: 23 },
                        getRealTimeData: false,
                        date: {
                            type: 1,
                            startDate: '',
                            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                        },
                    },
                    refreshReport: false,
                    showGeneratedLiveButton: Authorization.userHasRight(
                        UserRightsEnum.KPIViewGenerateLive,
                        this.props.user
                    ),
                    showSettingsModal: false,
                    reportData: undefined,
                    renderComplete: false,
                },
                this.getReportData
            );
        }
    }

    getReportData = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        const response = await _apiClient.getKpiViewData(
            this.state.reportParameters &&
                this.state.reportParameters.date &&
                this.state.reportParameters.date.endDate
                ? this.state.reportParameters.date.endDate
                : '',
            this.state.reportParameters && this.state.reportParameters.getRealTimeData
                ? true
                : false
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            const summary: IKpiSummaryCrosstabModel[] = [];
            let uniqueYear: string[] = [];
            // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
            if (response.payload.summaryData) {
                // NEW TYPESCRIPT CODE for when we upgrade
                // uniqueYear = [...new Set(response.payload.summaryData.map(item => item.year))];
                // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO REPLACE WITH CODE ABOVE
                response.payload.summaryData.forEach((element) => {
                    if (uniqueYear.find((x) => x == element.year) == undefined)
                        uniqueYear.push(element.year);
                });
                uniqueYear = uniqueYear.sort();

                response.payload.summaryData = response.payload.summaryData.sort((a, b) => {
                    if (
                        a.keyTypeSortOrder + a.yearSortOrder * 1000 >
                        b.keyTypeSortOrder + b.yearSortOrder * 1000
                    ) {
                        return 1;
                    }

                    if (
                        a.keyTypeSortOrder + a.yearSortOrder * 1000 <
                        b.keyTypeSortOrder + b.yearSortOrder * 1000
                    ) {
                        return -1;
                    }

                    return 0;
                });

                // NEW TYPESCRIPT CODE for when we upgrade
                // let uniqueKeyType = [...new Set(response.payload.summaryData.map(function(item) {
                //         return {
                //             keyType: item.keyType,
                //             keyTypeSortOrder: item.keyTypeSortOrder,
                //             borderBottom: item.borderBottom
                //         };
                //     }
                //     ))];
                // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO REPLACE WITH CODE ABOVE
                const uniqueKeyType: any[] = [];
                response.payload.summaryData.forEach((element) => {
                    if (
                        uniqueKeyType.find((x) => x.keyTypeSortOrder == element.keyTypeSortOrder) ==
                        undefined
                    )
                        uniqueKeyType.push({
                            keyType: element.keyType,
                            keyTypeSortOrder: element.keyTypeSortOrder,
                            borderBottom: element.borderBottom,
                        });
                });

                uniqueKeyType.forEach(function (item) {
                    // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
                    if (
                        item != undefined &&
                        uniqueYear.length > 3 &&
                        response &&
                        response.payload &&
                        response.payload.summaryData
                    ) {
                        // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
                        const y1o = response.payload.summaryData.find(
                            (x) =>
                                x.year == uniqueYear[0] &&
                                x.keyTypeSortOrder == item.keyTypeSortOrder
                        );
                        const y1 = y1o != undefined ? y1o.value : '';

                        // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
                        const y2o = response.payload.summaryData.find(
                            (x) =>
                                x.year == uniqueYear[1] &&
                                x.keyTypeSortOrder == item.keyTypeSortOrder
                        );
                        const y2 = y2o != undefined ? y2o.value : '';

                        // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
                        const y3o = response.payload.summaryData.find(
                            (x) =>
                                x.year == uniqueYear[2] &&
                                x.keyTypeSortOrder == item.keyTypeSortOrder
                        );
                        const y3 = y3o != undefined ? y3o.value : '';

                        // OLD TYPESCRIPT 2.4.1 COMPATIBLE SOLUTION TO NO NULL OPERATORS
                        const y4o = response.payload.summaryData.find(
                            (x) =>
                                x.year == uniqueYear[3] &&
                                x.keyTypeSortOrder == item.keyTypeSortOrder
                        );
                        const y4 = y4o != undefined ? y4o.value : '';

                        const summaryData: IKpiSummaryCrosstabModel = {
                            // keyType: `${this.getLeadingSpaces(item.keyType)}${item.keyType}`,
                            keyType: item.keyType,
                            keyTypeSortOrder: item.keyTypeSortOrder,
                            borderBottom: item.borderBottom,
                            year1: y1,
                            year2: y2,
                            year3: y3,
                            year4: y4,
                        };
                        summary.push(summaryData);
                    }
                });
            }
            this.setState({
                reportData: response.payload,
                pendingResponse: false,
                summaryData: summary,
                summaryYears: uniqueYear,
            });
        }
    };

    getLeadingSpaces = (keyType: string): string => {
        const leadingSpaces = keyType.search(/\S|$/);
        const spaces = '&nbsp;'.repeat(leadingSpaces);
        return spaces;
    };

    runLive = async () => {
        const params = this.state.reportParameters;
        params.getRealTimeData = true;
        if (params.date)
            params.date.endDate = params.getRealTimeData
                ? moment().format('YYYY-MM-DD')
                : moment().subtract(1, 'days').format('YYYY-MM-DD');
        this.setState({ reportParameters: params, refreshReport: true, showSettingsModal: false });
        this.getReportData();
    };

    getReportParameters = (): any => {
        return {
            endDate:
                this.state.reportParameters && this.state.reportParameters.getRealTimeData
                    ? moment().format('YYYY-MM-DD')
                    : moment().subtract(1, 'days').format('YYYY-MM-DD'),
            getRealTimeData:
                this.state.reportParameters && this.state.reportParameters.getRealTimeData
                    ? 'True'
                    : 'False',
        };
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <h1 style={{ marginLeft: '10px' }}>
                    Trends
                    {this.state.reportParameters.getRealTimeData ? (
                        <span className="padding-left-lg font-size-xs">As of Now</span>
                    ) : (
                        <span className="padding-left-lg font-size-xs">
                            As of 12:01am Central {Common.currentDate()}
                        </span>
                    )}
                    <ExportFileButton
                        url={ApiRoutes.ExportReport}
                        reportType={{ id: MyDocumentsTypesEnum.Trends.Value }}
                        reportParameters={{
                            reportType: { id: MyDocumentsTypesEnum.Trends.Value },
                            date: {
                                type: 1,
                                startDate: '',
                                endDate: '',
                            },
                            getRealTimeData: this.state.reportParameters.getRealTimeData,
                        }}
                        onError={this.handleExportError}
                        icon="fa-file-pdf"
                    />
                    {this.state.showGeneratedLiveButton &&
                    !this.state.reportParameters.getRealTimeData ? (
                        <button
                            className="btn btn-no-bg float-end text-gray"
                            onClick={() => this.setState({ showSettingsModal: true })}
                        >
                            <i className="fal fa-user-cog" />
                            &nbsp;Settings
                        </button>
                    ) : null}
                </h1>

                {/*                 <RelativeReportViewer reportName="Trends.trdp" heightInPixels={440} reportParameters={this.getReportParameters()} refreshReport={this.state.refreshReport}
                    renderComplete={() => this.setState({ renderComplete: true })} /> */}

                <Html5ReportViewer
                    reportName="Trends.trdp"
                    reportParameters={this.getReportParameters()}
                    refreshReport={false}
                    scale={1.3}
                />

                {this.state.showSettingsModal ? (
                    <Modal
                        centered
                        show={this.state.showSettingsModal}
                        onHide={() => this.setState({ showSettingsModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Trends </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                This view is generated at 12:01am Central time each day. You can run
                                this view real time, on an exception basis.
                            </p>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ showSettingsModal: false })}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-orange float-end" onClick={this.runLive}>
                                    Run
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
