import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorValueDisplayTypes,
    MyDocumentsTypesEnum,
    PackageSettlementStatusTypesEnum,
    SnapshotViewSortableSectionsEnum,
    UserRightsEnum,
} from '../utilities/Constants';
import {
    IDiscoveryServedModel,
    IEntityByDiagnosisCaseCountDetailModel,
} from '../interfaces/IStrategy';
import { IPortfolioOverviewItemModel, ISnapshotViewModel } from '../interfaces/ISnapshotView';

import ApiClient from '../services/apiClient';
import { ApiRoutes } from '../utilities/ApiRoutes';
import Authorization from '../stores/Authorization';
import Common from '../stores/Common';
import { DisplayMessages } from '../utilities/DisplayMessages';
import { ExportFileButton } from '../components/shared/ExportFileButton';
import { Help } from '../components/shared/Help';
import { ICrossTabDataBaseReportModel } from '../interfaces/Report/ITimelineReport';
import { IKeyValuePairModel } from '../interfaces/ILookup';
import { IPackageSettlementBaseModel } from '../interfaces/ICase';
import { IReportCaseDetailModel } from '../interfaces/Report/IReportCaseDetailModel';
import { IUserModel } from '../interfaces/IUser';
import { IValidation } from '../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../components/shared/Loader';
import { LocalRoutes } from '../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { PortfolioRisk } from '../components/report/PortfolioRisk';
import RelativeReportViewer from '../components/report/RelativeReportViewer';
import { ReportCorpRepRequestList } from '../components/report/ReportCorpRepRequestList';
import { ReportDepositionList } from '../components/report/ReportDepositionList';
import { ReportRequestForAuthList } from '../components/report/ReportRequestForAuthList';
import Sort from '../stores/Sort';
import { SortableHeader } from '../components/shared/SortableHeader';
import ValidateUtils from '../shared/validations';
import moment from 'moment';

const _apiClient = new ApiClient();

interface ISnapshotProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface ISnapshotState {
    pendingResponse: boolean;
    validation: IValidation;
    snapshotDetails?: ISnapshotViewModel;
    reportViewerRenderComplete?: boolean;
    refreshReport: boolean;
    getRealTimeData: boolean;
    showGeneratedLiveButton: boolean;
    endDate: string;
    showSettingsModal: boolean;
    mapRenderComplete?: boolean;
}

export class Snapshot extends React.Component<ISnapshotProps, ISnapshotState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            validation: {},
            showGeneratedLiveButton: Authorization.userHasRight(
                UserRightsEnum.SnapshotViewGenerateLive,
                this.props.user
            ),
            getRealTimeData: false,
            refreshReport: false,
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            showSettingsModal: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Snapshot, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadData(
            this.state && this.state.endDate ? this.state.endDate : '',
            this.state && this.state.getRealTimeData ? this.state.getRealTimeData : false
        );
    }

    componentDidUpdate(prevProps: any) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier !== this.props.refreshIdentifier
        ) {
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    showGeneratedLiveButton: Authorization.userHasRight(
                        UserRightsEnum.SnapshotViewGenerateLive,
                        this.props.user
                    ),
                    getRealTimeData: false,
                    refreshReport: false,
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    showSettingsModal: false,
                    mapRenderComplete: false,
                },
                () =>
                    this.loadData(
                        this.state && this.state.endDate ? this.state.endDate : '',
                        this.state && this.state.getRealTimeData
                            ? this.state.getRealTimeData
                            : false
                    )
            );
        }
    }

    loadData = async (endDate: string, getLiveData: boolean) => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getSnapshotViewData(endDate, getLiveData);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({
            snapshotDetails: response.payload!,
            pendingResponse: false,
        });
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    buildCrossTabHeader(reportData: ICrossTabDataBaseReportModel[], excludeTotal?: boolean) {
        const rows: any[] = [];
        const totalColumns = Math.max(...reportData.map((a) => a.columnSortOrder));
        const columns = [];
        columns.push(<th className="border-0"></th>);
        for (let j = 1; j < totalColumns + 1; j++) {
            const name = reportData.filter((x) => x.columnSortOrder === j)[0].columnDetail;
            const helpText = reportData.filter((x) => x.columnSortOrder === j)[0]
                .columnDetailHelpText;
            columns.push(
                <th className="border-0">
                    {name}
                    {helpText ? (
                        <Help type={ApplicationHelpType.Info} title={name} helpText={helpText} />
                    ) : null}
                </th>
            );
        }

        if (!excludeTotal) columns.push(<th className="border-0">Total</th>);
        rows.push(<tr>{columns}</tr>);

        return rows;
    }

    buildCrossTabRows(reportData: ICrossTabDataBaseReportModel[], excludeTotal?: boolean) {
        const rows: any[] = [];

        const totalColumns = Math.max(...reportData.map((a) => a.columnSortOrder));
        const totalRows = Math.max(...reportData.map((a) => a.rowSortOrder));

        for (let i = 1; i < totalRows + 1; i++) {
            const columns = [];
            let totalValue = 0;
            let totalValue1 = 0;
            let valueDisplayType1 = 0;
            columns.push(<td>{reportData.filter((x) => x.rowSortOrder === i)[0].rowDetail}</td>);
            for (let j = 1; j < totalColumns + 1; j++) {
                const value =
                    reportData.filter((x) => x.rowSortOrder === i && x.columnSortOrder === j)
                        .length > 0
                        ? reportData.filter(
                              (x) => x.rowSortOrder === i && x.columnSortOrder === j
                          )[0].value
                        : undefined;
                let text = '';
                if (value) {
                    totalValue += value;
                    const value1 = reportData.filter(
                        (x) => x.rowSortOrder === i && x.columnSortOrder === j
                    )[0].value1;
                    text = value.toString();
                    if (value1 != undefined) {
                        totalValue1 += value1;
                        if (
                            reportData.filter(
                                (x) => x.rowSortOrder === i && x.columnSortOrder === j
                            )[0].valueDisplayType1
                        )
                            valueDisplayType1 = reportData.filter(
                                (x) => x.rowSortOrder === i && x.columnSortOrder === j
                            )[0].valueDisplayType1;
                        switch (valueDisplayType1) {
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                                text += ' | ' + value1 + '%';
                                break;
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                text += ' | ' + Common.formatCurrency(value1.toString());
                                break;
                            default:
                                text += ' | ' + value1;
                        }
                    }
                }
                columns.push(<td>{text}</td>);
            }

            if (!excludeTotal) {
                let totalText = '';
                if (totalValue) {
                    totalText = totalValue.toString();
                    if (totalValue1) {
                        switch (valueDisplayType1) {
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                                totalText += ' | ' + totalValue1 + '%';
                                break;
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                totalText += ' | ' + Common.formatCurrency(totalValue1.toString());
                                break;
                            default:
                                totalText += ' | ' + totalValue1;
                        }
                    }
                }
                columns.push(<td>{totalText}</td>);
            }

            const styleString = {
                color: reportData.filter((x) => x.rowSortOrder === i)[0].format,
            };
            rows.push(
                <tr key={i} style={styleString}>
                    {columns}
                </tr>
            );
        }

        return rows;
    }

    sortData = (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        section?: SnapshotViewSortableSectionsEnum
    ) => {
        const snapshotDetails = this.state.snapshotDetails;
        if (snapshotDetails) {
            switch (section) {
                case SnapshotViewSortableSectionsEnum.PriorityCases:
                    snapshotDetails.priorityCases = this.sort(
                        snapshotDetails.priorityCases,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                case SnapshotViewSortableSectionsEnum.DiscoveryServedCases:
                    snapshotDetails.discoveryServedCases = this.sort(
                        snapshotDetails.discoveryServedCases,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                case SnapshotViewSortableSectionsEnum.Depositions:
                    snapshotDetails.depositions = this.sort(
                        snapshotDetails.depositions,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                case SnapshotViewSortableSectionsEnum.CorporateRepRequests:
                    snapshotDetails.corporateRepRequests = this.sort(
                        snapshotDetails.corporateRepRequests,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                case SnapshotViewSortableSectionsEnum.ClientRequests:
                    snapshotDetails.outstandingAuthRequests = this.sort(
                        snapshotDetails.outstandingAuthRequests,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                case SnapshotViewSortableSectionsEnum.TrialDates:
                    snapshotDetails.trialDates = this.sort(
                        snapshotDetails.trialDates,
                        key,
                        order,
                        subKey,
                        subGrandKey
                    );
                    break;
                default:
            }

            this.setState({ snapshotDetails: snapshotDetails });
        }
    };

    sort = (array: any[], key: string, order: string, subKey?: string, subGrandKey?: string) => {
        if (key.includes('date')) {
            array = array.sort(Sort.compareDate(key, subKey, order));
        } else {
            array = array.sort(Sort.compareValues(key, subKey, order, subGrandKey));
        }

        return array;
    };

    reportViewerRenderComplete = () => {
        this.setState({ reportViewerRenderComplete: true });
    };

    runLive = async () => {
        const getLiveData = true;
        const endDate = moment().format('YYYY-MM-DD');
        this.setState({
            getRealTimeData: getLiveData,
            endDate: endDate,
            refreshReport: true,
            showSettingsModal: false,
        });
        this.loadData(endDate, getLiveData);
    };

    getReportParameters = (): string => {
        let prefs = {};
        if (this.state.endDate && this.state.getRealTimeData != undefined) {
            prefs = {
                reportType: { id: MyDocumentsTypesEnum.Snapshot.Value },
                date: {
                    type: 1,
                    startDate: '',
                    endDate: this.state.endDate,
                },
                getLiveData: this.state.getRealTimeData ? 'True' : 'False',
            };
        }
        return JSON.stringify(prefs);
    };

    getMapReportParameters = (): any => {
        return {
            endDate: this.state.endDate,
            useGeneratedData: this.state.getRealTimeData ? 'False' : 'True', // reversed because Board intentionally uses opposite value of reports
        };
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (!this.state.snapshotDetails)
            return <span className="text-danger">{this.state.validation.model}</span>;

        const reportDateLabel = !this.state.snapshotDetails.effectiveDate
            ? 'As of Now'
            : `As of ${this.state.snapshotDetails.effectiveDate}`;

        const showSettingsButton =
            this.state.showGeneratedLiveButton &&
            !this.state.getRealTimeData &&
            this.state.snapshotDetails.effectiveDate !== null;

        return (
            <>
                {this.state.mapRenderComplete && (
                    <>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <h1 className="d-inline-block">Snapshot</h1>
                                <span className="font-size-xs ps-3">{reportDateLabel}</span>
                            </div>
                            <div className="col-sm-6">
                                {showSettingsButton && (
                                    <button
                                        className="btn btn-no-bg text-gray float-end"
                                        onClick={() => this.setState({ showSettingsModal: true })}
                                    >
                                        <i className="fal fa-user-cog" />
                                        &nbsp;Settings
                                    </button>
                                )}
                                <ExportFileButton
                                    url={ApiRoutes.ExportReport}
                                    reportType={{ id: MyDocumentsTypesEnum.Snapshot.Value }}
                                    reportParameters={{
                                        reportType: { id: MyDocumentsTypesEnum.Snapshot.Value },
                                        date: {
                                            type: 1,
                                            startDate: '',
                                            endDate: this.state.endDate,
                                        },
                                        getRealTimeData: this.state.getRealTimeData,
                                    }}
                                    onError={this.handleExportError}
                                    icon="fa-file-pdf"
                                />
                            </div>
                        </div>

                        <span className="text-danger">{this.state.validation.model}</span>

                        {/***********************************  INVENTORY  **********************************************************************************************/}
                        <h4>YTD Case Inventory Stats</h4>
                        <table className="table thick-outer-border">
                            <thead>
                                <tr>
                                    {this.state.snapshotDetails.inventory.map(
                                        (item: IKeyValuePairModel) => {
                                            return (
                                                <th className="align-top text-center">
                                                    {item.key!.split('<br/>').map((x, key) => {
                                                        return (
                                                            <span key={key}>
                                                                {x}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                            </thead>
                            <tbody className="align-top text-center">
                                <tr>
                                    {this.state.snapshotDetails.inventory.map(
                                        (item: IKeyValuePairModel) => {
                                            return <td>{item.value}</td>;
                                        }
                                    )}
                                </tr>
                            </tbody>
                        </table>

                        {/***********************************  CASE STATS  **********************************************************************************************/}
                        <h4>Case Statistics</h4>
                        <table className="table thick-outer-border">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Meso</th>
                                    <th>Lung Cancer</th>
                                    <th>Other Cancer</th>
                                    <th>Non-Malignancy</th>
                                    <th>Other</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.snapshotDetails.diagnosisDetails.map(
                                    (
                                        item: IEntityByDiagnosisCaseCountDetailModel,
                                        index: number
                                    ) => {
                                        return (
                                            <tr key={index}>
                                                <td className="ps-2">
                                                    {item.name}
                                                    {item.helpText ? (
                                                        <Help
                                                            type={ApplicationHelpType.Info}
                                                            title={item.name}
                                                            helpText={item.helpText}
                                                        />
                                                    ) : null}{' '}
                                                </td>
                                                <td className="col-sm-1" style={{ width: '15%' }}>
                                                    {Common.formatTextByDisplayType(
                                                        item.meso.toString(),
                                                        item.format
                                                    )}
                                                </td>
                                                <td className="col-sm-1" style={{ width: '15%' }}>
                                                    {Common.formatTextByDisplayType(
                                                        item.lungCancer.toString(),
                                                        item.format
                                                    )}
                                                </td>
                                                <td className="col-sm-1" style={{ width: '15%' }}>
                                                    {Common.formatTextByDisplayType(
                                                        item.otherCancer.toString(),
                                                        item.format
                                                    )}
                                                </td>
                                                <td className="col-sm-1" style={{ width: '15%' }}>
                                                    {Common.formatTextByDisplayType(
                                                        item.nonMalignancy.toString(),
                                                        item.format
                                                    )}
                                                </td>
                                                <td className="col-sm-1" style={{ width: '15%' }}>
                                                    {Common.formatTextByDisplayType(
                                                        item.other.toString(),
                                                        item.format
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>

                        {/***********************************  PORTFOLIO OVERVIEW  **********************************************************************************************/}
                        <h4>Portfolio Overview</h4>
                        <h5 className="font-weight-normal my-3">Open-Active Cases By State</h5>
                    </>
                )}
                <RelativeReportViewer
                    reportName="OpenActiveCasesMap.trdp"
                    heightInPixels={470}
                    reportParameters={this.getMapReportParameters()}
                    refreshReport={this.state.refreshReport}
                    renderComplete={() => this.setState({ mapRenderComplete: true })}
                />

                {this.state.mapRenderComplete && (
                    <>
                        <table className="table thick-outer-border mt-4">
                            <tbody>
                                <tr>
                                    <td className="padding-left-20">
                                        Top 10 States with Open-Active Cases
                                    </td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.top10States.map(
                                                (item: IPortfolioOverviewItemModel) => {
                                                    return (
                                                        <li>
                                                            {item.name} | {item.value}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-left-20">
                                        Top 10 Jurisdictions with Open-Active Cases
                                    </td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.top10Jurisdictions.map(
                                                (item: IPortfolioOverviewItemModel) => {
                                                    return (
                                                        <li>
                                                            {item.guid ? (
                                                                <Link
                                                                    target="_blank"
                                                                    to={LocalRoutes.JurisdictionStrategyDetail.replace(
                                                                        ':guid',
                                                                        item.guid
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ) : (
                                                                item.name
                                                            )}{' '}
                                                            | {item.value}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-left-20">
                                        Top 10 Plaintiffs Firms with Open-Active Cases
                                    </td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.top10PlaintiffsFirms.map(
                                                (item: IPortfolioOverviewItemModel) => {
                                                    return (
                                                        <li>
                                                            {item.guid ? (
                                                                <Link
                                                                    target="_blank"
                                                                    to={LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                                                                        ':guid',
                                                                        item.guid
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ) : (
                                                                item.name
                                                            )}{' '}
                                                            | {item.value}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-left-20">
                                        Top 5 Plaintiffs Firms with Alt-Monitored Cases
                                    </td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.top5AltPlaintiffsFirms.map(
                                                (item: IPortfolioOverviewItemModel) => {
                                                    return (
                                                        <li>
                                                            {item.guid ? (
                                                                <Link
                                                                    target="_blank"
                                                                    to={LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                                                                        ':guid',
                                                                        item.guid
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ) : (
                                                                item.name
                                                            )}{' '}
                                                            | {item.value}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/***********************************  Top 10 Occupations by SQ Score  **********************************************************************************************/}
                        <h5 className="font-weight-normal my-3">Top 10 Occupations by SQ Score</h5>
                        {this.state.snapshotDetails.top10OccupationBySQScore.length > 0 ? (
                            <table className="table table-sm">
                                <thead>
                                    {this.buildCrossTabHeader(
                                        this.state.snapshotDetails.top10OccupationBySQScore
                                    )}
                                </thead>
                                <tbody>
                                    {this.buildCrossTabRows(
                                        this.state.snapshotDetails.top10OccupationBySQScore
                                    )}
                                </tbody>
                            </table>
                        ) : null}

                        <table className="table" style={{ border: '2px var(--gray-color) solid' }}>
                            <tbody>
                                <tr>
                                    <td className="padding-left-20">Package Deals</td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.packageSettlements.map(
                                                (item: IPackageSettlementBaseModel) => {
                                                    return (
                                                        <li>
                                                            <Link
                                                                target="_blank"
                                                                to={LocalRoutes.PackageSettlementDetails.replace(
                                                                    ':guid',
                                                                    item!.guid!
                                                                )}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                            &nbsp;|&nbsp;{item.status!.displayName}
                                                            {item.status!.id ===
                                                            PackageSettlementStatusTypesEnum.Complete
                                                                ? ' | ' +
                                                                  Common.dateFormat(item.finalDate)
                                                                : ''}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-left-20">Authority Summary</td>
                                    <td style={{ width: '75%' }}>
                                        <ul>
                                            {this.state.snapshotDetails.authoritySummary.map(
                                                (item: IPortfolioOverviewItemModel) => {
                                                    return (
                                                        <li>
                                                            {item.name} ={' '}
                                                            {Common.formatCurrency(
                                                                item.value.toString()
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/***********************************  Portfolio Risk  **********************************************************************************************/}
                        <PortfolioRisk riskProfile={this.state.snapshotDetails.riskProfile} />

                        {/*********************************** Top 25 Priority Cases  **********************************************************************************************/}
                        <h4>Top 25 Hot Cases (Based on Projected Settlement)</h4>
                        <table className="table table-sm">
                            <thead>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <SortableHeader
                                        headerText="Case Name"
                                        sortKey="caseName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-2"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="SQ Score"
                                        sortKey="qScore"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Jurisdiction"
                                        sortKey="jurisdiction"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Plaintiffs Firm"
                                        sortKey="plaintiffsFirm"
                                        onSort={this.sortData}
                                        thClassName="col-sm-2"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Disease"
                                        sortKey="diagnosis"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Trial Date"
                                        sortKey="trialDate"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Projected Resolution"
                                        sortKey="expectedOutcome"
                                        onSort={this.sortData}
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Projected Settlement"
                                        sortKey="projectedSettlement"
                                        onSort={this.sortData}
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.PriorityCases
                                        }
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.snapshotDetails.priorityCases.map(
                                    (item: IReportCaseDetailModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-2">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-1">{item.qScore}</td>
                                                <td className="col-sm-3">{item.jurisdiction}</td>
                                                <td className="col-sm-2">{item.plaintiffsFirm}</td>
                                                <td className="col-sm-1">{item.diagnosis}</td>
                                                <td className="col-sm-1">
                                                    {Common.dateFormat(item.trialDate)}
                                                </td>
                                                <td>{item.expectedOutcome}</td>
                                                <td>
                                                    {item.projectedSettlement
                                                        ? Common.formatCurrency(
                                                              item.projectedSettlement.toString()
                                                          )
                                                        : ''}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>

                        {/***********************************  Resolution YTD and Projections   **********************************************************************************************/}
                        <h4>Resolution YTD and Projections</h4>
                        {this.state.snapshotDetails.resolutionProjections.length > 0 ? (
                            <table className="table table-sm">
                                <thead>
                                    {this.buildCrossTabHeader(
                                        this.state.snapshotDetails.resolutionProjections,
                                        true
                                    )}
                                </thead>
                                <tbody>
                                    {this.buildCrossTabRows(
                                        this.state.snapshotDetails.resolutionProjections,
                                        true
                                    )}
                                </tbody>
                            </table>
                        ) : null}

                        {/***********************************  Case Development Activity   **********************************************************************************************/}
                        <h4>Case Development Activity (Next 30 Days)</h4>
                        <h5 className="font-weight-normal my-3">Written Discovery</h5>
                        <table className="table table-sm">
                            <thead>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <SortableHeader
                                        headerText="Case Name"
                                        sortKey="caseName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.DiscoveryServedCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="SQ Score"
                                        sortKey="qScore"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.DiscoveryServedCases
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Type"
                                        sortKey="type"
                                        subKey="displayName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.DiscoveryServedCases
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Due"
                                        sortKey="dueDate"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.DiscoveryServedCases
                                        }
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.snapshotDetails.discoveryServedCases.map(
                                    (item: IDiscoveryServedModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-3">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid!
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-3">{item.qScore}</td>
                                                <td className="col-sm-3">
                                                    {item.type!.displayName}
                                                </td>
                                                <td className="col-sm-3">
                                                    {Common.dateFormat(item.dueDate)}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>

                        <h5 className="font-weight-normal my-3">Depositions</h5>
                        <ReportDepositionList
                            depositions={this.state.snapshotDetails.depositions}
                        />

                        <h5 className="font-weight-normal my-3">Corporate Rep Requests</h5>
                        <ReportCorpRepRequestList
                            data={this.state.snapshotDetails.corporateRepRequests}
                        />

                        <h5 className="font-weight-normal my-3">
                            Outstanding Requests for Authority
                        </h5>
                        <ReportRequestForAuthList
                            data={this.state.snapshotDetails.outstandingAuthRequests}
                        />

                        {/*********************************** Trial Dates  **********************************************************************************************/}
                        <h4>Trial Dates (Next 60 Days)</h4>
                        <table className="table table-sm">
                            <thead>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <SortableHeader
                                        headerText="Case Name"
                                        sortKey="caseName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-2"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="SQ Score"
                                        sortKey="qScore"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Jurisdiction"
                                        sortKey="jurisdiction"
                                        onSort={this.sortData}
                                        thClassName="col-sm-3"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Plaintiffs Firm"
                                        sortKey="plaintiffsFirm"
                                        onSort={this.sortData}
                                        thClassName="col-sm-2"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Disease"
                                        sortKey="diagnosis"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Trial Date"
                                        sortKey="trialDate"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Projected Resolution"
                                        sortKey="expectedOutcome"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                    />
                                    <SortableHeader
                                        headerText="Projected Settlement"
                                        sortKey="projectedSettlement"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                        dataObjectIdentifier={
                                            SnapshotViewSortableSectionsEnum.TrialDates
                                        }
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.snapshotDetails.trialDates.map(
                                    (item: IReportCaseDetailModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-2">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-1">{item.qScore}</td>
                                                <td className="col-sm-3">{item.jurisdiction}</td>
                                                <td className="col-sm-2">{item.plaintiffsFirm}</td>
                                                <td className="col-sm-1">{item.diagnosis}</td>
                                                <td className="col-sm-1">
                                                    {Common.dateFormat(item.trialDate)}
                                                </td>
                                                <td className="col-sm-1">{item.expectedOutcome}</td>
                                                <td className="col-sm-1">
                                                    {item.projectedSettlement
                                                        ? Common.formatCurrency(
                                                              item.projectedSettlement.toString()
                                                          )
                                                        : ''}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                        {this.state.showSettingsModal ? (
                            <Modal
                                centered
                                show={this.state.showSettingsModal}
                                onHide={() => this.setState({ showSettingsModal: false })}
                                backdrop={false}
                            >
                                <Modal.Header>
                                    <Modal.Title> Snapshot </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        This view is generated at 12:01am Central time each day. You
                                        can run this view real time, on an exception basis.
                                    </p>
                                    <div className="dialog-btn-div margin-top-sm">
                                        <button
                                            className="btn btn-default float-end"
                                            onClick={() =>
                                                this.setState({ showSettingsModal: false })
                                            }
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-orange float-end"
                                            onClick={this.runLive}
                                        >
                                            Run
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        ) : null}
                    </>
                )}
            </>
        );
    }
}
